.home-text {
    font-family: 'Nanum Gothic Coding', monospace;

    h1 {
        font-size: 65px;
    }
}

.container {
    flex-direction: column;
    align-items: flex-end;
}

.home-logo {
    max-width: 40%;
    width: 270px;
}

