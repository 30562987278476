.App {
    height: 120vh;
    // background-color: lightblue;
    background-image: url('../assets/background.png');
    opacity: 10;
    // background-size: cover;
}

// .App img {
//     height: 100%;
// }
