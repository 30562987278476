/*Desktop View*/
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
  height: 80px;
  border-top: solid 3px #000;
  border-bottom: solid 2px #000;
  font-family: 'Nanum Gothic Coding', monospace;
}

.menu-icon {
  display: none;
}

.logo-nav {
  display: flex;
  align-items: baseline;
  justify-content: space-evenly;
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 60px;
  height: 60px;
  color: #000;
}
.nav-options {
  padding-left: 25px;
  display: flex;
  grid-gap: 50px;
  list-style-type: none;
}
.mobile-option {
  display: none;
}
.option  a {
  text-decoration: none;
  color: #000;
}

.option :hover {
  color: white;
}

.mobile-menu {
  display: none;
}
@media (max-width: 648px) {
  /*Mobile View */
  .header {
    padding: 0px 10px;
  }
  .logo {
    width: 45px;
    height: 45px;
  }
  .nav-options {
    display: flex;
    width: 100%;
    height: 200px;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }
  .nav-options.active {
    background: #9899d1;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
  }

  .menu-icon {
    display:block;
    width: 45px;
    height: 45px;
  }
  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vw;
    padding: 30px 0px;
  }
}
.mobile-menu {
  display: block;
}