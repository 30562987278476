.footer {
    position: fixed;
    bottom: 0;
    background: black;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    min-height: 50px;
    padding: 0px 50px;
    color: rgba(0,0,0,0.3)
}

.footer-links {
    display: flex;
    align-items: center;
    margin: 5px;
}

.footer-links a {
    color: white;
    opacity: 0.15;
    text-decoration: none;
    font-size: 24px;
    padding: 0px 10px;
}

.footer-links a:hover {
    opacity: 1;
    color: rgb(230,68,30)
}
